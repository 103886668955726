import React from 'react'
import Typography from '@material-ui/core/Typography'

import Header from '../../../components/sections/Header'
import Text from '../../../components/sections/Text'
import Film from '../../../components/sections/Film'
import Podcast from '../../../components/sections/Podcast'
import Essay from '../../../components/sections/Essay'
import Soundtrack from '../../../components/sections/Soundtrack'
import Gallery from '../../../components/sections/Gallery'
import Footer from '../../../components/sections/Footer'

import { LinkTab } from '../../../components/Link'

import background from '../../../assets/images/arash-nassiri/background.png'
import still from '../../../assets/images/arash-nassiri/still.jpg'


import img1 from '../../../assets/images/arash-nassiri/1.png' 
import img2 from '../../../assets/images/arash-nassiri/2.png' 
import img3 from '../../../assets/images/arash-nassiri/3.png' 
import img4 from '../../../assets/images/arash-nassiri/4.png' 
import img5 from '../../../assets/images/arash-nassiri/5.png' 
import img6 from '../../../assets/images/arash-nassiri/6.png' 
import { PinDropSharp } from '@material-ui/icons'

const slides = [
  {
    label: 'O',
    image: img1
  },
  {
    label: 'T',
    image: img2
  },
  {
    label: 'D',
    image: img3
  },
  {
    label: 'F',
    image: img4
  },
  {
    label: 'F',
    image: img5
  },
  {
    label: 'F',
    image: img6
  },
];

export default function Album(props) {

  return (
    <>

      <Header
        name='Arash Nassiri'
        title='City of Tales'
        date='16 July - 12 August 2021'
      />

      <Text>
        <Typography variant='subtitle1'
          paragraph
        >
With the aim of transforming the city of Tehran into a modern Western-like capital, in 1965, the Iranian government commissioned the first Tehran Master Plan to the visionary Los Angeles-based architects and urban planners Victor Gruen and Abdolaziz Farmanfarmaian. The plan was to be implemented in 25 years, however, a few years later, the project was interrupted and the radical reconfiguration of Tehran envisioned by the two architects was never fully realised.
        </Typography>
        <Typography variant='subtitle1'
        paragraph
        >
City of Tales would have been a portrayal of present day-Tehran if its urban modernisation had not been frozen in the 1970s. Bathed in the fluorescence of Arabic signs scattered through the city and resonating of Persian voices, an altered Los Angeles landscape questions its own world-making status within dominant Western cultures.
         </Typography>
         <Typography variant='subtitle1'
        >
          Produced by Jonas Films. With the kind support of Han Nefkens Foundation.
        </Typography>
      </Text>

      <Film 
        url="575591974?h=3d8982d735"
        title="Arash Nassiri, City of Tales (2018) 16 min. 20”"
        titleShort="Arash Nassiri - City of Tales"
      />

      <Essay 
        title="Elmira Jafari & Carola Hein"
        subtitle="Transnational Building of a Modern Planning Regime in Iran"
       url="/articles/jafari-hein-transnational-building-of-a-modern-planning-regime-in-iran"
      >
      <Typography
        paragraph variant='subtitle1'
      >
 In 1962, in the midst of the Cold War and under the direct inﬂuence of John. F. Kennedy’s presidency, Mohammad Reza Shah launched his White Revolution, generally known as a top-down modernization project.<sup>13</sup> Due to rising socio-political unrest in Iran, Kennedy pressured the Shah to initiate reforms.<sup>14</sup> The Kennedy administration hoped that the White Revolution reforms could prevent Communist-inspired revolution in Iran. The administration, therefore, funnelled money to Iran to stabilize the US position in the Cold War contest. However, for the Shah, the key ambition of the White Revolution was to make Iran in general and Tehran in particular a ‘showcase of modernization’ in the Middle East region.<sup>15</sup> The White Revolution, therefore, embraced fundamental social and economic reforms in which land reform was among the most inﬂuential.<sup>16</sup>
      </Typography>
      </Essay>

      <Soundtrack 
        title="Utopia Tehran"
        subtitle="Bernard Hourcade in conversation with Felice Moramarco"
        caption=""
        image={still}
        color='#fff'
        url="https://soundcloud.com/felice-moramarco-726293220/bernard-hourcade-in-conversation-with-felice-moramarco"
      />

      <Gallery 
        slides={slides}
        title='Victor Gruen and Abdolaziz Farmanfarmaian, The Comprehensive Plan of Tehran, 1966.'
      />
      
      <Footer />

    </>
  );
}
