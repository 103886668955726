import React from 'react'

import Layout from '../../layouts/Artist'
import Main from '../../views/artists/arash-nassiri'

import background from '../../assets/images/arash-nassiri/background.png'


const Default = () => {

  const mainView = (
    <Layout background={background}>
      <Main />
    </Layout>
  )

  return mainView
}

export default Default
